<template>
  <div>
    <div>
      <b-card-actions title="Filters" action-collapse>
        <div>
          <b-row class="mb-1">
            <b-col md="8" lg="8" xs="12">
              <b-form-input
                placeholder="Search By Expense Title"
                type="text"
                class="d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font"
                v-on:keyup="onSearch"
                v-model.lazy="searchTerm"
              />
            </b-col>
            <b-col md="8" lg="4" xs="12">
              <flat-pickr
                  v-model="filterRangeDate"
                  v-on:input="loadItems"
                  class="form-control"
                  placeholder="Enter Due Date Range"
                  :config="{ mode: 'range'}"
              />
            </b-col>
          </b-row>
        </div>

        <div>
          <b-row>
            <b-col md="6" lg="4" xs="12">
              <v-select
                v-model="filterAccountId"
                :options="filterAccountOptionId"
                :reduce="(item) => item.id"
                v-on:input="filterByAccount"
                label="name"
                placeholder="By Account"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
            <b-col md="6" lg="4" xs="12">
              <v-select
                v-model="filterDepartmentId"
                :options="filterDepartmentOptionId"
                :reduce="(item) => item.id"
                v-on:input="filterByDepartment"
                label="name"
                placeholder="By Department"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>

            <b-col md="6" lg="4" xs="12">
              <v-select
                v-model="filterStatus"
                :options="expenseStatusTypeConstants"
                :reduce="(item) => item.value"
                v-on:input="filterByStatus"
                label="name"
                placeholder="By Expense Status"
                class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
          </b-row>
        </div>
      </b-card-actions>
    </div>

    <div>
      <b-card>
        <div>
          <!-- search input -->
          <div
            class="custom-search d-flex align-items-center justify-content-end"
          >
            <div
              class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
            >
              <vue-excel-xlsx
                  class="border-0 bg-transparent"
                  :data="rows"
                  :columns="exportColumns"
                  :file-name="'Expense'"
                  :file-type="'xlsx'"
                  :sheet-name="'Expense'"
              >
                <b-button
                    class="flex-shrink-0 mr-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                >
                  Export
                </b-button>
              </vue-excel-xlsx>

              <template v-if="$permissionAbility(EXPENSE_CREATE, permissions)">
                <b-button
                  class="flex-shrink-0 mr-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="gradient-primary"
                  v-on:click="showModal"
                >
                  Create
                </b-button>
              </template>
            </div>
          </div>

          <!-- table -->
          <vue-good-table
            styleClass="vgt-table table-custom-style striped"
            mode="remote"
            @on-per-page-change="onPerPageChange"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :rows="rows"
            :sort-options="{
              enabled: false,
              multipleColumns: true,
              initialSortBy: [{ field: 'id', type: 'desc' }],
            }"
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- format_remarks -->
              <span v-if="props.column.field === 'format_title'">
                <template v-if="props.row?.title">
                  <div class="cell-with-description">
                    Title: <span class="font-weight-bold">{{ props.row?.title }}</span>
                  </div>
                </template>
                <template v-if="props.row?.department">
                  <div class="cell-with-description">
                    Dept: <b>{{ props.row?.department?.data?.name }}</b>
                  </div>
                </template>
                <template v-if="props.row?.project">
                  <div class="cell-with-description">
                    Project:
                    <b>{{ props.row?.project?.data?.lead?.data?.title }}</b>
                  </div>
                </template>
                <template v-if="props?.row?.file">
                  <div class="cell-with-description">
                    <b-link :href="props?.row?.file" target="_blank">
                      Download / View Attachment
                    </b-link>
                  </div>
                  <!-- DownloadIcon -->
                </template>
                <template>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    v-on:click="showDescriptionModal(props.row?.remarks)"
                    class="btn-sm cell-with-description"
                  >
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                </template>
              </span>

              <template v-if="props?.column?.field === 'format_account_info'">
                <b>{{ props?.row?.account?.data?.name }}</b>
                <div class="cell-with-description">
                  <div v-if="props?.row?.account?.data?.email">
                    <span>
                      Email: <b>{{ props?.row?.account?.data?.email }}</b>
                    </span>
                  </div>
                  <div v-if="props?.row?.account?.data?.account_number">
                    <span>
                      Acc No: <b>{{ props?.row?.account?.data?.account_number }}</b>
                    </span>
                  </div>
                  <div v-if="props?.row?.account?.data?.account_holder_name">
                    <span>
                      Holder Name: <b>{{ props?.row?.account?.data?.account_holder_name }}</b
                      >
                    </span>
                  </div>
                  <div>
                    <span>
                     Currency:  <b>{{ props?.row?.account?.data?.currency }}</b>
                    </span>
                  </div>
                </div></template
              >

              <!-- Column: Status -->
              <span v-if="props.column.field === 'payed'">
                <b-badge pill :variant="statusVariant(props.row?.is_payed)">
                  {{ props.row?.is_payed ? "Paid" : "Due" }}
                </b-badge>
              </span>

              <template v-if="props?.column?.field === 'format_invoice'">
                <template v-if="props?.row?.payslip_created_date">
                  <template v-if="props?.row?.payslip_no">
                    <b-badge pill variant="light-primary">{{
                      props?.row?.payslip_no
                    }}</b-badge>
                  </template>
                  <template v-if="props?.row?.payslip_created_date">
                    <div class="table-cell-margin">
                      <b>Created On: </b>
                      <span>
                        {{
                          formatFnTableCreatedDate(
                            props?.row?.payslip_created_date
                          )
                        }}</span
                      >
                    </div>
                  </template>
                </template>
              </template>
              <!-- Column: expense_type -->
              <span v-if="props.column.field === 'expense_type'">
                {{ props.row?.expenseType?.data?.name }}
              </span>
              <!-- Column: Status -->
              <span v-if="props.column.field === 'account_name'">
                {{ props.row?.account?.data?.name }}
              </span>
              <!-- Column: format_amount -->
              <span v-if="props.column.field === 'format_amount'">
                <FormatCurrency :amount="props.row?.amount" :currency="props.row?.currency" />
              </span>

              <span v-if="props.column.field === 'format_currency'">
                <FormatCurrency :type="'currency'" :amount="props.row?.amount" :currency="props.row?.currency" />
              </span>

              <template v-if="props.column.field === 'formate_file'">
                <template v-if="props?.row?.file">
                  <b-link :href="props?.row?.file" target="_blank">
                    Download / View
                  </b-link>
                  <!-- DownloadIcon -->
                </template>
                <template v-else>
                  <span>No Files To Download</span>
                </template>
              </template>

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>

                    <template
                      v-if="$permissionAbility(PAYSLIP_ACCESS, permissions)"
                    >
                      <b-dropdown-item v-on:click="onShowPage(props?.row?.id)">
                        <feather-icon icon="FileTextIcon" class="mr-50" />
                        <span>Show Payslip</span>
                      </b-dropdown-item>
                    </template>
                    <template
                      v-if="$permissionAbility(EXPENSE_EDIT, permissions)"
                    >
                      <b-dropdown-item v-on:click="onShow(props?.row)">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Edit</span>
                      </b-dropdown-item>
                    </template>

                    <template
                      v-if="$permissionAbility(EXPENSE_DELETE, permissions)"
                    >
                      <b-dropdown-item v-on:click="onDelete(props.row.id)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Delete</span>
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['50', '100', '200']"
                    class="mx-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
        <!-- Pay Expense -->

        <!-- Add Expense -->
        <b-modal
          id="modal-expense-form"
          centered
          :title="modelType == 'editModel' ? 'Edit Expense' : 'Add Expense'"
          hide-footer
          @hidden="hiddenModal"
          no-close-on-backdrop
        >
          <validation-observer ref="expansesValidation">
            <b-form v-on:submit.prevent="validationForm">
              <!-- Department  -->
              <b-form-group label="Department *" label-for="department-id">
                <ValidationProvider
                  name="department_id"
                  v-slot="{ errors }"
                  vid="department_id"
                  rules="required"
                >
                  <v-select
                    id="department-id"
                    placeholder="Choose a Department"
                    v-model="departmentId"
                    :options="departmentIdOption"
                    v-on:input="onChangeDepartment"
                    :reduce="(option) => option.id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- Project  -->
              <b-form-group label="Project" label-for="project">
                <ValidationProvider
                  name="project"
                  v-slot="{ errors }"
                  vid="project_id"
                >
                  <v-select
                    id="project"
                    placeholder="Choose a Project "
                    v-model="projectId"
                    :options="projectIdOption"
                    v-on:input="onChangeProject"
                    :reduce="(option) => option.id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- expense_type -->
              <b-form-group label="Account *" label-for="account">
                <ValidationProvider
                  name="account"
                  v-slot="{ errors }"
                  vid="account"
                  rules="required"
                >
                  <v-select
                    id="account"
                    placeholder="Choose a Account"
                    v-model="selectAccountId"
                    :options="accountsOption"
                    :reduce="(option) => option.id"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <b-form-group label="Title *" label-for="title">
                <validation-provider
                  #default="{ errors }"
                  name="title"
                  vid="title"
                  rules="required|max:255"
                >
                  <b-form-input
                    id="title"
                    type="text"
                    v-model="title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Expense Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Amount *" label-for="amount">
                <validation-provider
                  #default="{ errors }"
                  name="amount"
                  vid="amount"
                  rules="required|integer"
                >
                  <b-form-input
                    id="amount"
                    type="number"
                    v-model="amount"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Expense Amount"
                    rules="required"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Instant Pay -->
              <template v-if="modelType != 'editModel'">
                <b-form-checkbox
                  v-model="isInstantPay"
                  class="custom-control-primary mr-2 mb-1"
                >
                  Instant Pay
                </b-form-checkbox>
              </template>
              <b-form-group
                :label="
                  modelType == 'editModel'
                    ? 'Date *'
                    : isInstantPay
                    ? 'Date'
                    : 'Date *'
                "
                label-for="date"
              >
                <ValidationProvider
                  name="date"
                  v-slot="{ errors }"
                  vid="date"
                  :rules="
                    modelType == 'editModel'
                      ? 'required'
                      : isInstantPay
                      ? ''
                      : 'required'
                  "
                >
                  <b-form-datepicker
                    id="date"
                    v-model="date"
                    :state="errors.length > 0 ? false : null"
                    locale="en-US"
                    reset-button
                    @context="onContextStartDate"
                    :disabled="modelType == 'editModel' ? false : isInstantPay"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>

              <!-- file -->

              <b-form-group
                label="Attach File (Ext allowed : jpg,jpeg,png,pdf,csv,xlsx,txt,docx)"
                label-for="file"
              >
                <validation-provider
                  #default="{ errors }"
                  name="file"
                  rules="size:2048|ext:jpg,jpeg,png,pdf,csv,xlsx,txt,docx"
                  vid="file"
                >
                  <b-form-file
                    id="file"
                    v-model="file"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- description -->
              <b-form-group label="Remarks *" label-for="remarks">
                <validation-provider
                  #default="{ errors }"
                  name="remarks"
                  vid="remarks"
                  rules="required"
                >
                  <b-form-textarea
                    id="remarks"
                    v-model="remarks"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Remarks"
                    rows="3"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- loading button -->
              <template v-if="isTypeFormSubmitLoading">
                <b-button class="float-right" variant="primary" disabled>
                  <b-spinner small />
                  Loading...
                </b-button>
              </template>

              <!-- submit button -->
              <template v-else>
                <b-button
                  type="submit"
                  class="float-right"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                >
                  Submit
                </b-button>
              </template>
            </b-form>
          </validation-observer>
        </b-modal>
        <b-modal
          id="description-modal"
          centered
          title="Remarks"
          hide-footer
          @hidden="hiddenDescriptionModal"
          no-close-on-backdrop
        >
          <b-card-text>
            {{ modalDescription }}
          </b-card-text>
        </b-modal>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormDatepicker,
  BFormFile,
  BFormCheckbox,
  BLink,
  BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { required, max, size, ext, max_value, min_value } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import { expenseStatusTypeConstants } from "@/helpers/constant/expenseStatusTypeConstant";
import {
  PAYSLIP_ACCESS,
  EXPENSE_CREATE,
  EXPENSE_EDIT,
  EXPENSE_DELETE,
} from "@/helpers/permissionsConstant";
import flatPickr from 'vue-flatpickr-component'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import FormatCurrency from "@/layouts/components/FormatCurrency.vue";
export default {
  name: "ExpenseView",
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormDatepicker,
    BFormFile,
    BFormCheckbox,
    BLink,
    BFormTextarea,
    flatPickr,
    BCardActions,
    FormatCurrency
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      PAYSLIP_ACCESS,
      EXPENSE_CREATE,
      EXPENSE_EDIT,
      EXPENSE_DELETE,
      filterRangeDate: null,
      modalDescription: "",
      modelType: "",
      filterStartDate: "",
      filterEndDate: "",
      filterAccountId: "",
      filterAccountOptionId: [],
      filterDepartmentId: "",
      filterDepartmentOptionId: [],
      filterProjectId: "",
      filterProjectOptionId: [],
      filterStatus: "",
      expenseStatusTypeConstants,
      //Pay Expense
      allExpenses: "",
      expenseId: "",
      expenseIdOption: [],
      expenseAmount: "",
      expenseAccountId: "",
      accountIdOption: [],
      expenseAttachment: null,
      expenseRemarks: "",

      //Create Expense
      departmentId: "",
      departmentIdOption: [],
      projectId: "",
      projectIdOption: [],
      allProjects: [],
      expenseTypeOption: [],
      selectAccountId: "",
      accountsOption: [],
      title: "",
      amount: "",
      remarks: "",
      date: "",
      file: null,
      isInstantPay: true,
      pageLength: 50,
      columns: [
        {
          label: "Due Date",
          field: "date",
          sortable: false,
          formatFn: this.formatFnTableFormateDate,
        },
        {
          label: "Paid Date",
          field: "paid_date",
          sortable: false,
          formatFn: this.formatFnTableFormateDate,
        },
        {
          label: "Expense Info",
          field: "format_title",
          sortable: false,
        },
        {
          label: "Account Info",
          field: "format_account_info",
          sortable: false,
        },
        {
          label: "Currency",
          field: "format_currency",
          sortable: false,
        },
        {
          label: "Amount",
          field: "format_amount",
          sortable: false,
        },
        {
          label: "Status",
          field: "payed",
          sortable: false,
        },
        {
          label: "Invoice Info",
          field: "format_invoice",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],

      exportColumns: [
        {
          label: "Due Date",
          field: "date"
        },
        {
          label: "Paid Date",
          field: "paid_date",
        },
        {
          label: "Title",
          field: "title",
        },
        {
          label: "Department",
          field: "department.data.name",
          sortable: false,
        },
        {
          label: "Account Info",
          field: "account.data.name",
        },
        {
          label: "Account Number",
          field: "account.data.account_number",
        },
        {
          label: "Account Email",
          field: "account.data.email"
        },
        {
          label: "Amount",
          field: "account.data.amount"
        },
        {
          label: "Currency",
          field: "account.data.currency"
        },
        {
          label: "Status",
          field: "is_payed"
        },
        {
          label: "Payslip No",
          field: "payslip_no",
          sortable: false,
        },
        {
          label: "Invoice Date",
          field: "created_at",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      // isDateDisable: false,
      delayTimer: null,
      isLoading: false,
      isExpenseFormLoading: false,
      isTypeFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "description", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 50,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  async created() {
    try {
      this.onCreateLoad();
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    handleDateRangeChange()
    {
      if (this.filterRangeDate && this.filterRangeDate !== '') {
        const dates = this.filterRangeDate.split(' to ');

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[0];
        } else if (dates.length === 2) {
          // Case where both start and end dates are provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[1];
        } else {
          // Handle invalid date range format
          console.error('Invalid date range format');
          // You can choose to handle this case differently based on your requirements
        }
      }
    },
    onShowPage(id) {
      this.$router.push({
        name: "admin-payslip",
        params: { id },
      });
    },
    showDescriptionModal(remarks) {
      if (remarks) {
        this.modalDescription = remarks;
      } else {
        this.modalDescription = "N/A";
      }

      this.$bvModal.show("description-modal");
    },
    hiddenDescriptionModal() {
      this.$bvModal.hide("description-modal");
    },

    async onCreateLoad() {
      const [department, getAccountActiveAll, expenses, accounts, projects] =
        await Promise.all([
          this.getDepartments(),
          this.getAccountActiveAll(),
          this.getDueExpenses(),
          this.getActiveAccountInfo(),
          this.getAllProjects(),
        ]);

      this.allExpenses = expenses;

      console.log(this.allExpenses);

      //active accounts
      this.accountIdOption = (accounts?.data?.data || []).map((item) => {
        let name = `${item?.name} (${item?.balance})`;

        return {
          name,
          id: item.id,
        };
      });

      this.allProjects = projects?.data?.data;
      this.projectIdOption = (this.allProjects || []).map((item) => {
        return {
          name: item?.title,
          id: item?.id,
          deptId: item?.department_id,
        };
      });

      this.filterAccountOptionId = (accounts?.data?.data || []).map((item) => {
        let name = item?.name;

        return {
          name,
          id: item.id,
        };
      });

      //expenses
      this.expenseIdOption = (this.allExpenses?.data?.data || []).map(
        (item) => {
          let name = `${item?.title} (${item?.amount})`;

          return {
            name,
            id: item?.id,
          };
        }
      );

      //departments
      this.departmentIdOption = (department?.data?.data || []).map((item) => {
        let name = item?.name;

        return {
          name,
          id: item?.id,
        };
      });

      this.filterDepartmentOptionId = (department?.data?.data || []).map(
        (item) => {
          let name = item?.name;

          return {
            name,
            id: item?.id,
          };
        }
      );
      // getAccountActiveAll
      this.accountsOption = (getAccountActiveAll?.data?.data || []).map(
        (item) => {
          let name = `${item?.name} (${item?.currency} ${item?.balance})`;

          return {
            name,
            id: item?.id,
          };
        }
      );
    },

    onChangeProject(projectId) {
      const projectInfo = (this.allProjects || []).find(
        (item) => item?.id == projectId
      );
      this.departmentId = projectInfo?.department_id;
    },
    async onChangeDepartment(deptId) {
      this.projectId = "";

      if (deptId) {
        this.projectIdOption = (this.allProjects || []).filter(
          (item) => item.department_id == deptId
        );

        this.projectIdOption = (this.projectIdOption || []).map((item) => {
          return {
            name: item?.title,
            id: item?.id,
            deptId: item?.department_id,
          };
        });
      } else {
        this.projectIdOption = (this.allProjects || []).map((item) => {
          return {
            name: item?.title,
            id: item?.id,
            deptId: item?.department_id,
          };
        });
      }
    },
    async getDepartmentProjects(id) {
      return await this.$api.get(`api/department/${id}/projects`);
    },

    async getActiveAccountInfo() {
      return await this.$api.get("api/accounts/active/all");
    },
    async getDueExpenses() {
      return await this.$api.get("api/expenses/due/all");
    },
    async getDepartments() {
      return await this.$api.get("api/departments/all");
    },
    async getAllProjects() {
      return await this.$api.get("api/projects/all");
    },
    formatFnTableFormateDate(value) {
      if (value) {
        return this.$moment(value).format("ll");
      }
    },
    statusVariant(value) {
      if (value) {
        return "light-success";
      }
      return "light-danger";
    },
    onContextStartDate(ctx) {
      this.date = ctx.selectedYMD;
    },
    formatFnTableCreatedDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
    },
    showModal() {
      this.$bvModal.show("modal-expense-form");
      this.projectIdOption = (this.allProjects || []).map((item) => {
        return {
          name: item?.title,
          id: item?.id,
          deptId: item?.department_id,
        };
      });
    },
    hiddenModal() {
      this.$bvModal.hide("modal-expense-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.expenseId = "";
      this.projectId = "";
      this.departmentId = "";
      this.selectAccountId = "";
      this.title = "";
      this.amount = "";
      this.date = "";
      this.remarks = "";
      this.file = null;
      this.isInstantPay = true;
    },

    onShowPayModal(value) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Pay This Expense?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            this.expenseId = value?.id;

            this.expenseAccountId = value?.account_id;

            await this.$api.post(`/api/expenses/${this.expenseId}/pay`, {
              account_id: this.expenseAccountId,
            });
            this.loadItems();
            this.onCreateLoad();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Expense Payed Successfully ",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },
    hideExpenseModal() {
      this.$bvModal.hide("modal-pay-expense-form");
      this.expenseId = "";
      this.expenseAmount = "";
      this.expenseAccountId = "";
      this.expenseAttachment = null;
      this.expenseRemarks = "";
    },

    async onShow(value) {
      this.modelType = "editModel";
      this.expenseId = value?.id;
      this.departmentId = value?.department_id;
      await this.onChangeDepartment(value?.department_id);
      this.projectId = value?.project_id;
      this.selectAccountId = value?.account_id;
      this.title = value?.title;
      this.amount = value?.amount;
      this.date = value?.date;
      this.remarks = value?.remarks;

      this.showModal();
    },

    async onDelete(id) {
      try {
        await this.$api.delete(`/api/expenses/${id}`);

        this.loadItems();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Success",
            icon: "BellIcon",
            variant: "success",
            text: "Successfully Deleted",
          },
        });
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },
    filterExpenseByDueDate() {
      this.loadItems();
    },
    filterByAccount() {
      this.loadItems();
    },
    filterByDepartment() {
      this.loadItems();
    },
    filterByProject() {
      this.loadItems();
    },
    filterByStatus() {
      this.loadItems();
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async setExpenseAmount() {
      const selectedExpense = (this.allExpenses?.data?.data || []).find(
        (item) => {
          if (item?.id === this.expenseId) {
            return item;
          }
        }
      );

      this.expenseAccountId = selectedExpense?.account_id;
      this.expenseAmount = selectedExpense?.amount;
    },
    async getExpenses(params) {
      return await this.$api.get(
        "api/expenses?include=project.lead,department,account",
        {
          params: {
            show: params.show,
            page: params.page,
            sort: params.sort,
            q: params.q,
            filterStartDate: params.filterStartDate,
            filterEndDate: params.filterEndDate,
            filterAccountId: params.filterAccountId,
            filterDepartmentId: params.filterDepartmentId,
            filterStatus: params.filterStatus,
          },
        }
      );
    },

    async getAccountActiveAll() {
      return await this.$api.get("api/accounts/active/all");
    },

    async loadItems() {
      try {
        this.handleDateRangeChange();
        const [getExpenses] = await Promise.all([
          this.getExpenses({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterStartDate: this.filterStartDate,
            filterEndDate: this.filterEndDate,
            filterAccountId: this.filterAccountId,
            filterDepartmentId: this.filterDepartmentId,
            filterStatus: this.filterStatus,
          }),
        ]);

        const data = getExpenses?.data?.data;
        const meta = getExpenses?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.expansesValidation.validate().then(async (success) => {
        if (success) {
          try {
            this.$refs.expansesValidation.reset();
            if (this.modelType == "editModel") {
              this.isTypeFormSubmitLoading = true;

              const formData = new FormData();
              formData.append("_method", "PUT");

              if (this.file) {
                formData.append("file", this.file);
              }

              if (this.departmentId) {
                formData.append("department_id", this.departmentId);
              }

              if (this.projectId) {
                formData.append("project_id", this.projectId);
              }

              if (this.selectAccountId) {
                formData.append("account_id", this.selectAccountId);
              }

              if (this.title) {
                formData.append("title", this.title);
              }

              if (this.amount) {
                formData.append("amount", this.amount);
              }

              if (this.date) {
                formData.append("date", this.date);
              }

              if (this.remarks) {
                formData.append("remarks", this.remarks);
              }

              await this.$api.post(`/api/expenses/${this.expenseId}`, formData);

              this.isTypeFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();
              this.onCreateLoad();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Updated",
                },
              });
            } else {
              this.isTypeFormSubmitLoading = true;

              const formData = new FormData();

              if (this.file) {
                formData.append("file", this.file);
              }
              if (this.departmentId) {
                formData.append("department_id", this.departmentId);
              }

              formData.append("project_id", this.projectId);

              if (this.selectAccountId) {
                formData.append("account_id", this.selectAccountId);
              }

              if (this.title) {
                formData.append("title", this.title);
              }

              if (this.amount) {
                formData.append("amount", this.amount);
              }

              if (this.isInstantPay) {
                formData.append(
                  "date",
                  this.$moment(new Date()).format("Y-MM-DD")
                );
              } else {
                formData.append("date", this.date);
              }

              if (this.remarks) {
                formData.append("remarks", this.remarks);
              }

              formData.append("is_payed", this.isInstantPay);

              await this.$api.post("/api/expenses", formData);

              this.isTypeFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();
              this.onCreateLoad();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isTypeFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.expansesValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
    // payExpenseValidationForm: async function () {
    //   this.$refs.payExpenseValidation.validate().then(async (success) => {
    //     if (success) {
    //       try {
    //         this.$refs.payExpenseValidation.reset();
    //         this.isExpenseFormLoading = true;

    //         await this.$api.post(`/api/expenses/${this.expenseId}/pay`, {
    //           account_id: this.expenseAccountId,
    //         });

    //         this.isExpenseFormLoading = false;

    //         this.hideExpenseModal();
    //         this.loadItems();
    //         this.onCreateLoad();
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: "Success",
    //             icon: "BellIcon",
    //             variant: "success",
    //             text: "Expense Successfully Paid",
    //           },
    //         });
    //       } catch (error) {
    //         this.isExpenseFormLoading = false;
    //         if (error?.response?.data?.message) {
    //           this.$toast({
    //             component: ToastificationContent,
    //             props: {
    //               title: "Error",
    //               icon: "BellIcon",
    //               variant: "danger",
    //               text: error?.response?.data?.message,
    //             },
    //           });
    //         }

    //         if (error?.response?.data?.errors) {
    //           this.$refs.payExpenseValidation.setErrors(
    //             error?.response?.data?.errors
    //           );
    //         }
    //       }
    //     }
    //   });
    // },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
.cell-with-description,
.cell-with-description div {
  margin: 2px 0px;
}
</style>
